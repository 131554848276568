import { useEffect, useState } from "react";

interface TextViewerProps {
  url: string;
}

const TextViewer: React.FC<TextViewerProps> = ({ url }) => {
  const [content, setContent] = useState<string>("Loading...");

  useEffect(() => {
    const fetchText = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error("Failed to fetch file content");
        const text = await response.text();
        setContent(text);
      } catch (error) {
        setContent("Error loading file.");
        console.error(error);
      }
    };

    fetchText();
  }, [url]);

  return <>{content}</>;
};

export default TextViewer;