import React, { useEffect, useMemo, useState } from "react";
import {
  SecondaryLink,
} from "../../components/buttons/buttons";
import { ToastType } from "../../parts/toast/toast";
import { useDispatch } from "react-redux";
import toastUtil from "../../utils/toast.util";
import TextViewer from "../../components/textviewer/textviewer"; 
import { redirect, useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { BarsArrowDownIcon, Bars3Icon } from "@heroicons/react/24/solid";
import "../../components/pagination/paginate.scss";
import "./maps.scss";
import "./map.scss";
import MinioService from "../../services/minio.service";
import MapService from "../../services/maps.service";
import { MinioModel } from "../../models/minioModel";

const minioService = new MinioService();
const mapService = new MapService();
const Map = () => {
  const [reload, setReload] = useState<boolean>(true);
  const [title, setTitle] = useState<string>("");

  const [monthly, setMonthly] = useState<MinioModel[]>([]);
  const [weekly, setWeekly] = useState<MinioModel[]>([]);
  const [url, setUrl] = useState<string | undefined>('');
  const [urlCache, setUrlCache] = useState<{ [key: string]: string }>({});
  const [displayer, setDisplayer] = useState<string>("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const arrayMapPrefix = [
    { id: 1, label: "Rapport global", link: "-global-reporting.txt" },
    { id: 2, label: "Rapport partiel", link: "-reporting.txt" },
    { id: 3, label: "Comptes enseignants créés", link: "-number-of-teachers-by-department.html" },
    { id: 4, label: "Nombre de comptes enseignants créées par département, regroupés par clusters", link: "-number-of-teachers-by-department-grouped-by-clusters.html" },
    { id: 5, label: "Courbe cumulative", link: "-accounts-creation-cumulative-sum.jpg" },
    { id: 6, label: "Nombre de comptes enseignants créés par jour", link: "-number-of-teeacher-accounts-created-plot-chart.jpg" },
    { id: 7, label: "Nombre de comptes enseignants créés par rapport à la population du département", link: "-number-of-active-teachers-on-population.html" },
  ];

  const [openAccordionIndexes, setOpenAccordionIndexes] = useState<{ [key: string]: number }>({
    weekly: -1,  // Initially no accordion open for weekly tab
    monthly: -1,  // Initially no accordion open for monthly tab
  });

  const [selectedSubItemIndexes, setSelectedSubItemIndexes] = useState<{
    [key: string]: { parentIndex: number; subIndex: number };
  }>({
    weekly: { parentIndex: -1, subIndex: -1 },  // Initially no item selected for weekly tab
    monthly: { parentIndex: -1, subIndex: -1 },  // Initially no item selected for monthly tab
  });

  const [selectedSubItem, setSelectedSubItem] = useState<{
    tabName: string;
    parentIndex: number;
    subIndex: number;
  }>({
    tabName: "weekly", // Default value
    parentIndex: 0,
    subIndex: 0,
  });

  const toggleAccordion = (tabName: string, index: number) => {
    setOpenAccordionIndexes((prevIndexes: any) => {
      const isAccordionOpen = prevIndexes[tabName] === index;

      // If a new accordion is opened, reset the selected subitem to the first one
      if (!isAccordionOpen) {

        setSelectedSubItemIndexes((prevIndexes: any) => ({
          ...prevIndexes,
          [tabName]: { parentIndex: -1, subIndex: -1 }, // Store selected item for the current tab
        }));

        setUrl('');
      }

      // Toggle the accordion open/close
      return {
        ...prevIndexes,
        [tabName]: isAccordionOpen ? -1 : index, // Toggle logic for each tab
      };
    });
  };


  const handleSubItemClick = (parentIndex: number, subIndex: number, link: string, name: string, tabName: string) => {
    setSelectedSubItemIndexes((prevIndexes: any) => ({
      ...prevIndexes,
      [tabName]: { parentIndex, subIndex }, // Store selected item for the current tab
    }));

    defineUrl(parentIndex, subIndex, link, name, tabName);
  };

  const [activeTab, setActiveTab] = useState("weekly");

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    // Check if the URL for the selected item is cached for this tab
    const cacheKey = `${selectedSubItemIndexes[tab].parentIndex}-${selectedSubItemIndexes[tab].subIndex}-${tab}`;

    if (urlCache[cacheKey] && urlCache[cacheKey]) {
      // Use the cached URL for the tab if available
      setUrl(urlCache[cacheKey]);
    } else {
      setUrl('');
    }
  };

  const search = () => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const name = params.get("name") || "";
    setTitle(name);
    mapService.getMap(name).then((res) => {
      if (!res.success) {
        const toastData: ToastType = {
          title: "Erreur de chargement",
          texts: [{ text: res.data }],
          status: "error",
          position: "bottomRight",
          autoClose: false,
        };

        if (res.redirect !== null) {
          return navigate(res.redirect, { state: { error: res.data } });
        }
        return toastUtil.show(toastData, dispatch);
      }

      if (res.data.type) {
        setDisplayer(res.data.type);

        if (res.data.type === 'weekly/monthly') {
          searchMinio('weekly/', setWeekly);
          searchMinio('monthly/', setMonthly);
          handleTabClick('weekly');
        }
      }
    });
  };

  const defineUrl = (parentIndex: number, subIndex: number, path: string, name: string, tabName: string) => {
    let complete_path: string = activeTab + '/' + name + '/' + path;

    const cacheKey = `${parentIndex}-${subIndex}-${tabName}`;
    const tabCache = urlCache[cacheKey] || '';

    if (tabCache) {
      // Use the cached URL for the tab if it exists
      setUrl(tabCache);
    } else {
      minioService.getMinio(complete_path, false).then((res) => {
        if (!res.success) {
          const toastData: ToastType = {
            title: "Erreur de chargement",
            texts: [{ text: res.data }],
            status: "error",
            position: "bottomRight",
            autoClose: false,
          };

          if (res.redirect !== null) {
            return navigate(res.redirect, { state: { error: res.data } });
          }
          return toastUtil.show(toastData, dispatch);
        }
        if (res.data && res.data.data.url) {
          // Update the cache for the specific tab
          setUrlCache((prevCache: any) => ({ ...prevCache, [cacheKey]: res.data.data.url }));
          setUrl(res.data.data.url);
        }
      });
    }
  };

  const searchMinio = (str: string, func: any) => {
    minioService.getMinio(str).then((res) => {
      if (!res.success) {
        const toastData: ToastType = {
          title: "Erreur de chargement",
          texts: [{ text: res.data }],
          status: "error",
          position: "bottomRight",
          autoClose: false,
        };

        if (res.redirect !== null) {
          return navigate(res.redirect, { state: { error: res.data } });
        }
        return toastUtil.show(toastData, dispatch);
      }
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const name = params.get("name") || "";
      if (res.data && res.data.data) {
        const transformedData = res.data.data.map((item: any) => new MinioModel(item, arrayMapPrefix, name))
          .sort((a: MinioModel, b: MinioModel) => (a.name > b.name ? -1 : 1));
        func(transformedData);
      }
    });
  };

  useEffect(() => {
    if (reload)
      search();
  }, [reload]);

  const tabs = [
    { name: "weekly", data: weekly },
    { name: "monthly", data: monthly },
  ];

  return (
    <div className="flex flex-col maps">
      <header className="flex justify-between items-center pr-5">
        <h2 className="title">Map : {title}</h2>
        <SecondaryLink
          label="Annuler"
          to="/Maps"
          btnClassname="backButton"
          textClassname="text-sm"
          icon={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
        />
      </header>
      {displayer === 'weekly/monthly' && (
        <div className="tabs-container">
          {/* Tab Buttons */}
          <div className="tab-buttons">
            <button
              className={`tab-button ${activeTab === 'weekly' ? 'active' : ''}`}
              onClick={() => handleTabClick('weekly')}
            >
              Hebdomadaire
            </button>
            <button
              className={`tab-button ${activeTab === 'monthly' ? 'active' : ''}`}
              onClick={() => handleTabClick('monthly')}
            >
              Mensuelle
            </button>
          </div>

          {/* Tab Content */}
          <div className="tab-content">
            <div className="tab-view flex">
              {tabs.map(
                (tab) =>
                  activeTab === tab.name && (
                    <>
                      <ul
                        role="list"
                        className="overflow-y-auto niceScroll mx-2 divide-y divide-blue-200 lista"
                      >
                        {tab.data.map((week: MinioModel, index: number) => (
                          <li key={week.name} className="w-full">
                            {/* Accordion Header */}
                            <div
                              className={`
                                flex justify-between py-2 px-2 cursor-pointer hover:bg-indigo-50  font-bold
                                ${openAccordionIndexes[tab.name] === index ? "bg-indigo-100" : ""}
                              `}
                              onClick={() => toggleAccordion(tab.name, index)} // Use separate state per tab
                            >
                              {week.name}
                              <span>
                                {openAccordionIndexes[tab.name] === index
                                  ? <BarsArrowDownIcon className="h-5 w-5" aria-hidden="true" /> // Minus icon when open // Plus icon when closed 
                                  : <Bars3Icon className="h-5 w-5" aria-hidden="true" />}
                              </span>
                            </div>

                            {/* Accordion Items */}
                            {openAccordionIndexes[tab.name] === index && (
                              <ul className={`accord-${week.name.toLowerCase().replace(/\s+/g, '-')}`}>
                                {week.items.map((item, subIndex) => (
                                  <li
                                    key={`${week.name}-${subIndex}`}
                                    className={`li-${subIndex}
                                      pl-1 py-2 cursor-pointer text-gray-500 lefty
                                      ${selectedSubItemIndexes[tab.name]?.parentIndex === index && selectedSubItemIndexes[tab.name]?.subIndex === subIndex && "bg-gray-200"}
                                    `}
                                    onClick={() => handleSubItemClick(index, subIndex, item.link, week.name, tab.name)}
                                  >
                                    {item.label}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                      {url && (
                        <div className="map_visualizer">
                          {url.includes(".html") ? (
                            <iframe
                              src={url}
                              className="w-full h-full"
                              frameBorder="0"
                              title={`${activeTab} Map Viewer`}
                              key={url || activeTab} // Force reload based on the URL or tab
                            />
                          ) : url.includes(".txt") ? (
                            <pre className="w-full h-full overflow-auto p-4 bg-gray-100">
                              {/* Fetch and display the content */}
                              <TextViewer url={url} />
                            </pre>
                          ) : (
                            <img
                              src={url || ""}
                              alt={`${activeTab} Image Viewer`}
                              className="w-full h-full object-contain" // Adjust object-fit if needed
                            />
                          )}
                        </div>
                      )}
                    </>
                  )
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Map;


