import { useNavigate, useLoaderData } from "react-router-dom";
import Maps from "./maps/maps";
import Subscriptions from "./subscriptions/subscriptions";
import { useEffect } from "react";

export const DynamicHome: React.FC = () => {
    const userRole = useLoaderData();
    const navigate = useNavigate();

    useEffect(() => {
        if (userRole === "viewer") {
           return navigate("/maps");
        } else {
            return navigate("/subscriptions");
        }
    }, [userRole, navigate]); // update url for navbar active item

    return userRole !== "viewer" ? <Subscriptions /> : <Maps />;
};
