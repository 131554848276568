import React, { useState, useMemo, useEffect, useCallback } from "react";
import {
  PrimaryButton,
  PrimaryLink,
  SecondaryLink,
  TertiaryButton,
} from "../../components/buttons/buttons";
import SearchBar, { InputType } from "../../components/searchBar/searchBar";
import { PlusIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import PaginatedItems from "../../components/pagination/paginate";
import SubscriptionsService from "../../services/subscriptions.service";

import { ColumnDef } from "@tanstack/react-table";
import { subscriptionType } from "../../models/subsrcription.model";
import Table, { actionsType } from "../../components/table/table";
import { ToastType } from "../../parts/toast/toast";
import toastUtil from "../../utils/toast.util";
import { useDispatch } from "react-redux";
import { closeToast } from "../../store/toast.slice";
import { useLocation, useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/20/solid";
import DotsSpinner from "../../components/dotsSpinner/dotsSpinner";
import { collectionType } from "../../models/collection.model";
import CollectionsService from "../../services/collections.service";

const Subscriptions: React.FC = () => {
  const [searching, setSearching] = useState<boolean>(false);
  const [collectionListOpen, setCollectionListOpen] = useState<boolean>(false);
  const [reload, setReaload] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<subscriptionType[]>([]);
  const [uaiEtab, setUaiEtab] = useState<string>("");
  const [selectedUai, setSelectedUai] = useState<string>("");
  const [message, setMessage] = useState<string>("Veuillez spécifier un code UAI ou une collection");
  const [userRole, setUserRole] = useState<string>("");
  const [collectionLabel, setCollectionLabel] = useState<string>("");
  const [style, setStyle] = useState<string>("tableCollection");
  const { currentItems, paginate } = PaginatedItems(10, subscriptions);
  const subscriptionsService = new SubscriptionsService();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = localStorage.getItem("currentUser");
  const [collection, setCollection] = useState<collectionType>();
  const [filteredCollections, setFilteredCollections] = useState<collectionType[]>();
  const [selectedCol, setSelectedCol] = useState<collectionType>();
  const [collections, setCollections] = useState<collectionType[]>([]);
  const location = useLocation();
  const [title, setTitle] = useState<string>("Rechercher un abonnement");
  const collectionsService = new CollectionsService();


  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "libelleRessource",
        header: "Collection",
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "uaiEtab",
        header: "Uai",
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "commentaireAbonnement",
        header: "Commentaire",
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "debutValidite",
        header: "Début",
        accessorFn: (row) =>
          `${new Date(row.debutValidite).toLocaleDateString("fr-FR")}`,
        sortingFn: "datetime",
      },
      {
        accessorKey: "finValidite",
        header: "Fin",
        accessorFn: (row) =>
          `${new Date(row.finValidite).toLocaleDateString("fr-FR")}`,
        sortingFn: "datetime",
      },
      {
        accessorKey: "nbLicenceEleve",
        header: "#Elève",
        cell: (cell) =>
          displayNumberOrCheck(cell.row.original, "ELEVE", "nbLicenceEleve"),
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "nbLicenceEnseignant",
        header: "#Prof",
        cell: (cell) =>
          displayNumberOrCheck(
            cell.row.original,
            "ENSEIGNANT",
            "nbLicenceEnseignant"
          ),
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "nbLicenceDocumentalist",
        header: "#Doc.",
        cell: (cell) =>
          displayNumberOrCheck(
            cell.row.original,
            "DOCUMENTALISTE",
            "nbLicenceProfDoc"
          ),
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "nbLicenceAutrePersonnel",
        header: "#Autre",
        cell: (cell) =>
          displayNumberOrCheck(
            cell.row.original,
            "AUTRE PERSONNEL",
            "nbLicenceAutrePersonnel"
          ),
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "nbLicenceGlobale",
        header: "#Global",
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "codeProjetRessource",
        header: "Projet",
        sortingFn: "alphanumeric",
      },
    ],
    []
  );

  useEffect(() => {
    //user role is important to limit functionalities as multiple subscription
    if (user) {
      setUserRole(JSON.parse(user).role);
    }
  }, []);

  useEffect(() => {
    if (reload) {
      search();
      setReaload(false);
    }
  }, [reload]);

  useEffect(() => {
    // UPDATE SUBSCRIPTION
    if (location.state?.collection) {
      setCollection(location.state?.collection);
      setSelectedCol(location.state?.collection);
      let collection: collectionType = location.state?.collection;

      if (collection)
        setTitle("Abonnements de " + collection.label);

    }
  }, [location.state]);

  useEffect(() => {
  if (!selectedUai && !selectedCol) {
    setTitle("Rechercher un abonnement");
    setSubscriptions([]);
  } else {
    search();
  }
}, [selectedUai, selectedCol]);

  const search = useCallback(
    () => {
      if(!selectedUai && !selectedCol){
        setTitle("Rechercher un abonnement");
        return setSubscriptions([]);
      }

      setSearching(true);
      let filter: any = {};
      let title = "Rechercher un abonnement par ";
      let noResultMessage = "Aucun abonnement sur cet établissement ou établissement inconnu";

      if (selectedCol?.idArk && selectedUai) {
        title += ` UAI : ${selectedUai} & collection : ${selectedCol?.label}`;
        setStyle("tableBoth");
        noResultMessage = "Aucun abonnment sur cet établissement et cette collection";
        filter = { uaiEtab: selectedUai, idRessource: "ark:/86527/" + selectedCol.idArk };
      } else if (selectedCol?.idArk) {
        title += `collection : ${selectedCol?.label}`;
        setStyle("tableUai");
        noResultMessage = "Aucun abonnment sur cette collection";
        filter = { idRessource: "ark:/86527/" + selectedCol.idArk };
      } else {
        title += `UAI : ${selectedUai}`;
        setStyle("tableCollection");
        filter = { uaiEtab: selectedUai };
      }

      setTitle(title);

      subscriptionsService.getSubscriptions(filter).then((res) => {
        if (!res?.success) {
          const toastData: ToastType = {
            title: "Erreur de chargement",
            texts: [{ text: res?.data }],
            status: "error",
            position: "bottomRight",
            autoClose: false,
          };

          if (res && res.redirect !== null) {
            return navigate(res.redirect, { state: { error: res.data } });
          }
          return toastUtil.show(toastData, dispatch);
        }
        if (res.data.length < 1)
          setMessage(noResultMessage);

        setSubscriptions(res.data);
        setSearching(false);
      });
    },
    [selectedCol, selectedUai]
  );

  const onUaiChange = (uai: string) => {
    setUaiEtab(uai);
    if(uai.length === 8) setSelectedUai(uai);
  };

  const onCollectionChange = (col: string) => {
    if (collections.length < 1) {
      collectionsService.getCollections(10000).then((res: any) => {
        if (res.success)
          setCollections(res.data.docs);
      });
    }
    setCollectionListOpen(col.length > 0);
    setCollectionLabel(col);
    setFilteredCollections(collections.filter((c) => c.label.toLowerCase().includes(col.toLowerCase())));
  };

  const onResetSearchCollection = () => {
    setCollectionLabel("");
    setCollectionListOpen(false);
    setSelectedCol(undefined);
  }

  const onResetSearchUAI = () => {
    setUaiEtab("");
    setSelectedUai("");
  }

  const subscriptionSearchInputs: InputType[] = [
    { name: "UAI", type: "text", onChange: onUaiChange, onReset: onResetSearchUAI, value: uaiEtab },
    { name: "Collection", type: "text", onChange: onCollectionChange, onReset: onResetSearchCollection, value: collectionLabel },
  ];

  const displayNumberOrCheck = (row: any, cible: string, nbLicence: string) => {
    return row.nbLicenceGlobale ? (
      row.publicCible.includes(cible) ? (
        <XMarkIcon className="h-5 w-5 mx-auto" />
      ) : (
        ""
      )
    ) : row[nbLicence] ? (
      row[nbLicence]
    ) : (
      ""
    );
  };

  const updateSubscription = (subscription: subscriptionType) => {
    navigate("/newSubscription", { state: { update: subscription } });
  };

  const onCollectionItemClick = (col: collectionType) => {
    setSelectedCol(col);
    setCollectionLabel(col.label);
    setCollectionListOpen(false);
  }

  const validateDeletion = (subscription: subscriptionType) => {
    const toastData: ToastType = {
      title: "Suppression",
      texts: [{ text: "Voulez-vous supprimer cette abonnement ?" }],
      status: "neutre",
      autoClose: false,
      blocking: true,
      position: "middle",
      buttons: [
        <TertiaryButton
          key="no"
          label="Non"
          onClick={() => dispatch(closeToast())}
          textClassname="text-sm px-6 py-2"
        />,
        <div onClick={() => dispatch(closeToast())}>
          <PrimaryButton
            key="yes"
            label="Oui"
            onClick={() => deleteSubscription(subscription.idAbonnement)}
            textClassname="text-sm"
          />
        </div>,
      ],
    };
    toastUtil.show(toastData, dispatch);
  };

  const deleteSubscription = (idAbonnement: string) => {
    subscriptionsService.deleteSubscription(idAbonnement).then((res: any) => {
      const toastData: ToastType = {
        title: res.success ? "Abonnement supprimé" : "Erreur",
        texts: [{ text: res.success ? "" : res.data }],
        status: res.success ? "success" : "error",
        position: "bottomRight",
        autoClose: res.success,
      };
      toastUtil.show(toastData, dispatch);

      setReaload(true);
    });
  };

  const actions: actionsType = {
    update: updateSubscription,
    delete: validateDeletion,
  };

  return (
    <div className="subscriptions flex flex-col">
      <div className="flex justify-between items-center">
        <h2 className="title">{title}</h2>
        <div className="button-group">
          {!collection &&
            <PrimaryLink
              label="Ajouter un abonnement"
              to="/newSubscription"
              btnClassname="addButton"
              textClassname="text-sm"
              icon={<PlusIcon className="h-5 w-5" aria-hidden="true" />}
            />
          }
          {!collection && userRole === "admin" && (
            <PrimaryButton
              label="Ajouter plusieurs abonnements"
              onClick={() => {
                navigate("/newSubscription", { state: { multiple: true } });
              }}
              btnClassname="addButton"
              textClassname="text-sm"
              icon={<PlusIcon className="h-5 w-5" aria-hidden="true" />}
            />
          )}
          {collection && (
            <SecondaryLink
              label="Annuler"
              to="/collections"
              btnClassname="backButton mr-4"
              textClassname="text-sm"
              icon={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
            />
          )
          }
        </div>
      </div>
      {!collection &&
        <>
          <SearchBar
            inputs={subscriptionSearchInputs}
          />
          {collectionListOpen && <ul
            role="list"
            className="max-h-96 overflow-y-auto niceScroll mx-6 divide-y divide-blue-200 rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]"
          >
            {filteredCollections?.map((collection: collectionType) => {
              return (
                <li
                  className={"w-full flex justify-between py-3 px-5 cursor-pointer hover:bg-indigo-50 " +
                    (selectedCol?.acronym === collection.acronym ? "bg-indigo-100" : "")}
                  key={collection.idArk}
                  onClick={() => onCollectionItemClick(collection)}
                >
                  {collection.label}
                </li>
              );
            })}
          </ul>}
        </>
      }
      {searching && <DotsSpinner className="centered" />}
      {currentItems.length > 0 &&
        <div className="mr-4 ml-4">
          <Table columns={columns} data={currentItems} actions={actions} tableStyle={style} />
          {paginate}
        </div>
      }
      {!searching && currentItems.length < 1 &&
        <div className="mt-8 p-3 mx-auto text-md">
          {message}
        </div>
      }
    </div>
  );
};

export default Subscriptions;
